import React, { useState, useEffect, useCallback, useRef } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "./App.css";
import Switch from "react-switch";
import produce from "immer";
require("codemirror/lib/codemirror.css");
require("codemirror/theme/material.css");
require("codemirror/theme/neat.css");
require("codemirror/mode/clojure/clojure.js");
require("codemirror/keymap/vim.js");
require("codemirror/mode/javascript/javascript.js");

const SomeExamples: Array<[string, string]> = [
  [
    "Hello Emoji",
    "/#eyJ2aW1FbmFibGVkIjp0cnVlLCJjZWxsQ29udGVudCI6WyI7OyBQdWxsIGluIGVtb2ppIG9uZSBmcm9tIG5wbVxuKGRlZiBlbW9qaSAobnBtIFwiZW1vamlvbmVcIikpXG5cbjs7IFNvbWUgc3VnYXJcbihkZWZuIHNob3J0LT5lbW9qaSBbc10gXG4gIChqc0NhbGxNZXRob2QgZW1vamkgXCJzaG9ydG5hbWVUb1VuaWNvZGVcIiBzKSlcblxuKHByaW50bG4gXCJIZWxsbyBXb3JsZCFcIiAoc2hvcnQtPmVtb2ppIFwiOndhdmU6XCIpKVxuOzsgUHJlc3MgU2hpZnQrRW50ZXIgdG8gcnVuIHRoaXMiXX0="
  ],
  [
    "Flip a coin",
    "/#eyJ2aW1FbmFibGVkIjpmYWxzZSwiY2VsbENvbnRlbnQiOlsiOzsgUHVsbCB0aGUgXCJyYW5kb21cIiBwYWNrYWdlIGZyb20gbnBtXG4oZGVmIHIgKG5wbSBcInJhbmRvbVwiKSlcbjs7IFByaW50IGhlYWRzIG9yIHRhaWxzIHJhbmRvbWx5XG4ocHJpbnRsbiAoaWYgKGpzQ2FsbE1ldGhvZCByIFwiYm9vbGVhblwiKSBcImhlYWRzXCIgXCJ0YWlsc1wiKSlcbjs7IFByZXNzIFNoaWZ0K0VudGVyIHRvIHJ1biB0aGlzIl19"
  ],
  [
    "Bart – Estimated Time",
    "/#eyJ2aW1FbmFibGVkIjp0cnVlLCJjZWxsQ29udGVudCI6WyI7OyBQdWxsIHRoZSBcInJhbmRvbVwiIHBhY2thZ2UgZnJvbSBucG1cbihkZWYgcmVxdWVzdCAobnBtIFwic3luY3JlcXVlc3RcIikpXG4oZGVmIHBhcnNlSlNPTiAobnBtIFwicGFyc2UtanNvblwiKSlcblxuKGRlZm4gZXRkLXVybCBbc3RhdGlvbiBkaXJdIFxuICAoc3RyIFwiaHR0cDovL2FwaS5iYXJ0Lmdvdi9hcGkvZXRkLmFzcHg/Y21kPWV0ZCZvcmlnPVwiIHN0YXRpb24gXCIma2V5PU1XOVMtRTdTTC0yNkRVLVZWOFYmanNvbj15JmRpcj1cIiBkaXIpKVxuXG47OyBQcmVzcyBTaGlmdCtFbnRlciB0byBydW4gdGhpc1xuKGRlZiByZXN1bHQgKGpzLT5jbGogKGpzQ2FsbE1ldGhvZCByZXF1ZXN0IFwic3luY1wiIChldGQtdXJsIFwiV09BS1wiIFwiU1wiKSAjKGZuIFthXSAocHJpbnRsbiBhKSkpKSlcblxuKGRlZiBiYXJ0LWRhdGEgKGpzLT5jbGogKHBhcnNlSlNPTiAoZ2V0LWluIHJlc3VsdCBbXCJyZXNwb25zZVwiLCBcImJvZHlcIl0pKSkpXG4oZGVmIGFsbC1lc3RpbWF0ZXNcbiAoZmxhdHRlblxuICAgKG1hcCAjKGdldCAlIFwiZXRkXCIpXG4gICAgIChnZXQtaW4gYmFydC1kYXRhIFtcInJvb3RcIiBcInN0YXRpb25cIl0pKSkpXG5cbihkZWYgbmV4dC10cmFpbi10by1TRi1mcm9tLVdPQUtcbiAobWFwICMoJSBcIm1pbnV0ZXNcIilcbiAgICAgIChmbGF0dGVuXG4gICAgICAgKG1hcCAjKCUgXCJlc3RpbWF0ZVwiKSBhbGwtZXN0aW1hdGVzICkpKSlcblxuKGNsai0+anMgbmV4dC10cmFpbi10by1TRi1mcm9tLVdPQUspIl19"
  ]
];

type initialState = {
  vimEnabled?: boolean;
  cellContent?: Array<string>;
};
let initialState: initialState = {};
try {
  initialState = JSON.parse(atob(window.location.hash.slice(1)));
} catch (e) {}
console.log(initialState);

const defaultFirstCell = `;; Pull the "random" package from npm
(def r (npm "random"))
;; Print heads or tails randomly
(println (if (jsCallMethod r "boolean") "heads" "tails"))
;; Press Shift+Enter to run this code

;; Press Option/Alt+Enter to start a new cell`;

const calcFirstLineNumber = (cellIndex: number, cellContent: Array<string>) => {
  let firstLineNumber = 1;
  for (let index = 0; index < cellIndex; index++) {
    firstLineNumber += cellContent[index].split("\n").length;
  }
  return firstLineNumber;
};

const evalClj = (body: string) =>
  fetch("https://untitled-sbly98eppinw.runkit.sh/", {
    method: "POST",
    body
  }).then(x => x.json());

type CellResult = {
  returnVal: any;
  stdOut: Array<string>;
  err?: string;
};

const App = () => {
  const [vimEnabled, setVim] = useState(initialState.vimEnabled || false);
  const [cellContent, setCellContent] = useState<Array<string>>(
    initialState.cellContent || [defaultFirstCell]
  );
  const [redrawCounter, setRedrawCounter] = useState(0);

  const [err, setErr] = useState<string | null>(null);

  const [cellResults, setCellResults] = useState<Array<CellResult>>([]);

  const onEval = useCallback(async () => {
    // Format to handle multi cell
    const cells = cellContent
      .map(c => `(__nextCellBlock) (__pushRes (do ${c}\n))\n`)
      .join("\n");
    const script = `(do ${cells})`;

    // Loading state
    setCellResults(cellResults => {
      return produce(cellResults, draft => {
        return cellContent.map((_, i) => {
          return {
            stdOut: ["Loading"],
            returnVal: null
          };
        });
      });
    });

    const res = await evalClj(script);

    if (res && res.name && res.name === "Error") {
      setErr(res.stack);
    } else if (res.map) {
      res.map((cellResult: [Array<string>, string], i: number) =>
        setCellResults(cellResults => {
          return produce(cellResults, draft => {
            draft[i] = {
              stdOut: cellResult[0],
              returnVal: cellResult[1]
            };
          });
        })
      );
    }
  }, [cellContent, setCellResults, setErr]);

  useEffect(() => {
    setErr(null);
    window.location.hash = btoa(JSON.stringify({ vimEnabled, cellContent }));
  }, [vimEnabled, cellContent]);

  return (
    <div className="App">
      <div className="App-header">CljKit</div>
      <Settings
        vimEnabled={vimEnabled}
        onChangeVimEnabled={v => {
          setVim(v);
        }}
      />
      {err && <pre className="replErr">{err}</pre>}
      {cellContent.map((v, i) => {
        return (
          <Cell
            key={i}
            initialContent={v}
            onChangeText={t =>
              setCellContent((s: Array<string>) => {
                return produce(s, ds => {
                  ds[i] = t;
                });
              })
            }
            focus={i === cellContent.length - 1}
            redrawCounter={redrawCounter}
            cellResult={cellResults[i]}
            onEval={onEval}
            vimEnabled={vimEnabled}
            firstLineNumber={calcFirstLineNumber(i, cellContent)}
            onNewCell={() => {
              setCellContent(s => [
                ...s,
                ";; Type some Clj code here. Shift+Enter runs it.\n"
              ]);
            }}
            onRemoveCell={() => {
              setCellContent(s => {
                return produce(s, ds => {
                  const f = ds.splice(i, 1);
                  console.log("removing", i, f);
                });
              });
              setRedrawCounter(c => c + 1);
            }}
          />
        );
      })}
      <Examples examples={SomeExamples} />
    </div>
  );
};

const Examples = (props: { examples: Array<[string, string]> }) => {
  return (
    <div className="examples">
      <h3>Examples:</h3>
      <ul>
        {props.examples.map(([title, url], i) => (
          <li>
            <a
              key={i}
              onClick={() => setTimeout(() => window.location.reload(), 10)}
              href={url}
            >
              {title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

type SettingsProp = {
  vimEnabled: boolean;
  onChangeVimEnabled: (v: boolean) => void;
};
const Settings = ({ vimEnabled, onChangeVimEnabled }: SettingsProp) => {
  return (
    <div className="App-settings">
      <span>Enable Vim</span>
      <Switch checked={vimEnabled} onChange={onChangeVimEnabled} />
    </div>
  );
};

type CellProps = {
  vimEnabled: boolean;
  onChangeText: (t: string) => void;
  redrawCounter: number;
  initialContent?: string;
  onNewCell: () => void;
  onRemoveCell: () => void;
  onEval: (s: string) => void;
  cellResult?: CellResult;
  focus?: boolean;
  firstLineNumber: number;
};

const Cell = ({
  cellResult,
  vimEnabled,
  onChangeText,
  initialContent,
  firstLineNumber,
  onNewCell,
  onRemoveCell,
  focus,
  onEval,
  redrawCounter
}: CellProps) => {
  // Hack to not rerender when this prop changes
  const [content, setContent] = useState(initialContent);
  useEffect(() => {
    setContent(initialContent);
  }, [redrawCounter]); // eslint-disable-line
  // Codemirror doesn't update properly?
  const onEvalRef = useRef(onEval);
  useEffect(() => {
    onEvalRef.current = onEval;
  }, [onEval]);
  return (
    <div className="Cell">
      <CodeMirror
        value={content || ";; Type some Clj code here"}
        selection={{ ranges: [], focus }}
        options={{
          mode: "clojure",
          theme: "material",
          lineNumbers: true,
          firstLineNumber: firstLineNumber,
          keyMap: vimEnabled ? "vim" : "default"
        }}
        onChange={(editor, data, value) => {
          onChangeText(value);
        }}
        onKeyDown={(editor, event) => {
          // @ts-ignore
          if (event.key === "Enter" && event.shiftKey) {
            const content = editor.getValue();
            event.preventDefault();
            onEvalRef.current(content);

            // evalClj(content).then(result => setEvalResult(result));
            // @ts-ignore
          } else if (event.key === "Enter" && event.altKey) {
            event.preventDefault();
            onNewCell();
          }
        }}
      />
      <div className="removeCell">
        <button onClick={onRemoveCell}>Remove</button>
      </div>
      {cellResult && (
        <>
          {cellResult.stdOut.length !== 0 && (
            <pre>{" " + cellResult.stdOut.join(" ")}</pre>
          )}
          {cellResult && cellResult.returnVal && (
            <pre> => {JSON.stringify(cellResult.returnVal)} </pre>
          )}
          {cellResult && cellResult.err && <pre> => {cellResult.err} </pre>}
        </>
      )}
    </div>
  );
};

export default App;

// const opts = (outBuf) => ({bindings: {
//   println: (...x) => { outBuf[outBuf.length - 1][0].push(...(x.map(t => JSON.stringify(t))), "\n"); },

//   __nextCellBlock: () => { outBuf.push([[]]); },
//   __pushRes: () => {},
//   npm: dynamicRequire,
//   jsCall: (f, t, ...args) => f.call(t, ...args),
//   jsCallMethod: (o, m, ...args) => o[m].call(o, ...args),
//   jsGet: (o, k) => o[k],
// }})
// sci.evalString(`(println "hello world")`, opts([]))
// // require("request-promise")
// // require('request')
// opts([]).bindings.npm("request")
// opts([]).bindings.println("hi")

// // opts.bindings.npm("request-promise")

// // sci.evalString(`(do
// //   (npm "request")
// //   (println (npm "request-promise"))
// //   3
// // )`, opts([]))

// const eval = s => {
// const outBuf = []
// const res = sci.evalString(s, opts(outBuf))
// if (outBuf[outBuf.length - 1][1] === undefined) {
//   outBuf[outBuf.length -1][1] = res
// }
// return outBuf
// }
